<template>
  <vs-popup
    :title="videoName"
    :fullscreen="fullScreen"
    :active.sync="popupOpen"
    v-bind:minimized="minimized"
    @update:active="isActive"
    class="video-call m-0 p-0"
  >
    <i
      v-on:click="changeSize()"
      class="
        full-screen-btn
        vs-icon
        notranslate
        icon-scale
        vs-popup--close vs-popup--close--icon
        material-icons
        null
      "
      style="background: rgb(255, 255, 255)"
      >{{ fullScreen ? 'fullscreen_exit' : 'fullscreen' }}</i
    >
    <i
      v-on:click="minimized = !minimized"
      class="
        minimize-btn
        vs-icon
        notranslate
        icon-scale
        vs-popup--close vs-popup--close--icon
        material-icons
        null
      "
      style="background: rgb(255, 255, 255)"
      >{{ minimized ? 'maximize' : 'minimize' }}</i
    >
    <div class="iframe-container">
      <iframe
        v-if="callOpen"
        v-bind:allow="allow"
        v-bind:src="videoCallLink"
        height="100%"
        width="100%"
        frameborder="0"
      ></iframe>
    </div>
  </vs-popup>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    videoCallPopupTitle: {
      type: String,
      required: true
    },
    videoCallLink: {
      type: String,
      required: true
    }
  },
  name: 'VideoCall',
  data() {
    return {
      callOpen: false,
      popupOpen: false,
      fullScreen: false,
      minimized: false,
      allow: 'camera; microphone; display-capture',
      videoName: ''
    }
  },
  computed: {
    ...mapState([
      'lang',
      'languageSelected',
      'videoCall',
      'activeChatUser',
      'config',
      'session'
    ])
  },
  methods: {
    setVideoCallPopupOpen(value) {
      if (value !== true) {
        this.popupOpen = false
      }
      this.videoName = this.videoCallPopupTitle + ''
      this.popupOpen = true
      this.callOpen = true
      this.fullScreen = false
      this.minimized = false
      this.createCall()
    },
    isActive(value) {
      if (value == false) {
        this.finishCall()
        console.log('Closed')
      }
      //var iframeWindow = window.frames[0]
      //var element = iframeWindow.document.getElementsByClassName("selector")[0];
      //element.click();
      //console.log('Active--', value)
    },
    changeSize() {
      if (this.minimized == false) {
        this.fullScreen = !this.fullScreen
      } else {
        this.minimized = false
      }
    },
    finishCall() {
      this.callOpen = false
    },
    createCall() {
      /** Destroy call */
      window.onmessage = e => {
        if (e.data && e.origin == this.config.videocall) {
          console.log('EVENT IFRAME', e.data)
          try {
            var data = JSON.parse(e.data)
            switch (data.type) {
              case 'close':
                console.log('CLOSE')
                this.popupOpen = false
                this.callOpen = false
                break
              case 'loaded':
                console.log('LOADED')
                break
            }
          } catch (error) {
            console.log(error)
          }
        }
      }
    }
  },
  mounted() {
    this.videoName = this.videoCallPopupTitle + ''
    console.log('Mounted')
  },
  created() {
    console.log('Created')
  },
  beforeDestroy: function () {
    console.log('beforeDestroy')
  },
  afterDestroy: function () {
    console.log('afterDestroy')
  }
}
</script>

<style>
.video-call[minimized='true'] {
  width: 16vw !important;
  height: 200px !important;
  overflow: hidden !important;
  left: calc(400px + 80px + 2.2rem) !important;
  top: calc(103px + 77px) !important;
}

.video-call[minimized='true'] .vs-popup--background {
  display: none;
}

.video-call[minimized='true'] .full-screen-btn,
.video-call[minimized='true'] .minimize-btn,
.video-call[minimized='true'] .vs-popup--close {
  top: 3px;
}

.video-call.con-vs-popup .vs-popup--close.full-screen-btn {
  position: absolute;
  right: 40px;
  top: 3px;
}
.video-call.con-vs-popup .vs-popup--close.minimize-btn {
  position: absolute;
  right: 80px;
  top: 3px;
}

.video-call.con-vs-popup {
  position: absolute;
  margin: 0;
}

.video-call.con-vs-popup .vs-popup--close {
  position: absolute;
  right: 0;
  top: 3px;
}

.video-call.con-vs-popup .vs-popup {
  width: 1200px;
}

.video-call .vs-popup {
  position: relative;
}

.video-call.con-vs-popup,
.video-call.con-vs-popup.fullscreen .vs-popup {
  width: 100%;
}

iframe {
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.iframe-container {
  width: 100%;
  height: 100%;
}
@media (max-width: 1200px) {
  div.video-call[minimized='true'] {
    z-index: 1;
    width: 16vw !important;
    min-width: 200px;
    height: 200px !important;
    overflow: hidden !important;
    left: 2.2rem !important;
    top: calc(103px + 77px) !important;
  }
  div.video-call[minimized='true'] div.vs-popup {
    margin: 0;
  }
}
</style>
