<template>
  <div>
    <label for="attachAudio">
      <feather-icon
        icon="MusicIcon"
        class="icon-large cursor-pointer"
      ></feather-icon>
    </label>
    <input
      id="attachAudio"
      type="file"
      accept="audio/*"
      enctype="multipart/form-data"
      style="display:none"
      ref="file"
      v-on:change="handleFileUpload()"
    />
    <br />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
      file: ''
    }
  },

  methods: {
    submitFile() {
      // let formData = new FormData()
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0]
      this.UPDATE_ATTACHMENT_FILE(this.file)
      this.$emit('close')
    },

    ...mapMutations(['UPDATE_ATTACHMENT_FILE'])
  }
}
</script>
