import Request from './request'
import state from '../store/state'

const PATH = {
  VIDEO_CALL: '/videoCall'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getVideoCall = id => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${state.config.konecta}${PATH.VIDEO_CALL}/${id}`, config)
}

const createVideoCall = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${state.config.konecta}${PATH.VIDEO_CALL}`,
    payload,
    config
  )
}

export default {
  getVideoCall,
  createVideoCall,
}
