<template>
  <div>
    <feather-icon
      v-if="!attachmentFile.name"
      @click="showFilesTypes"
      icon="PaperclipIcon"
      class="cursor-pointer button mt-1"
      style="width:20px !important"
    ></feather-icon>
    <feather-icon
      v-else
      @click="cancelAttachedFile"
      icon="XIcon"
      class="cursor-pointer button mt-1"
      style="width:20px !important"
    ></feather-icon>

    <div v-if="filesTypes" class="content-files-types">
      <AttachImage @close="showFilesTypes" />
      <!-- <AttachVideo @close="showFilesTypes" /> -->
      <AttachAudio @close="showFilesTypes" />
      <AttachFile @close="showFilesTypes" />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import AttachImage from './attach-files/AttachImage'
// import AttachVideo from './attach-files/AttachVideo'
import AttachAudio from './attach-files/AttachAudio'
import AttachFile from './attach-files/AttachFile'
export default {
  components: {
    AttachImage,
    // AttachVideo,
    AttachAudio,
    AttachFile
  },
  data() {
    return {
      filesTypes: false
    }
  },
  computed: {
    ...mapState(['attachmentFile'])
  },
  methods: {
    showFilesTypes() {
      this.filesTypes = !this.filesTypes
    },
    cancelAttachedFile() {
      this.UPDATE_ATTACHMENT_FILE({})
    },
    ...mapMutations(['UPDATE_ATTACHMENT_FILE'])
  }
}
</script>

<style scoped>
.button {
  margin: 8px 0px 8px 13px;
  position: absolute !important;
  left: 40px;
  top: 14px;
  color: #b1c6d0;
  z-index: 10;
}

.button:hover {
  transform: scale(1.1);
}

.content-files-types {
  position: absolute;
  z-index: 2000;
  background: #ffffff;
  margin-left: 27px;
  margin-top: -170px;
  border-radius: 7px;
  padding: 5px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  animation: enter 0.5s ease;
}

@keyframes enter {
  0% {
    opacity: 0;
    margin-top: 0px;
  }
  100% {
    opacity: 1;
  }
}
</style>
