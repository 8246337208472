<template>
  <div v-if="userId != null" class="chat__header">
    <vs-navbar
      class="p-4 flex navbar-custom"
      :class="'bg-' + chatStatusColor + '-gradient'"
      type="flat"
    >
      <div class="relative flex mr-4">
        <feather-icon
          icon="MenuIcon"
          class="mr-4 cursor-pointer"
          v-if="isSidebarCollapsed"
          @click.stop="$emit('openContactsSidebar')"
        ></feather-icon>
        <vs-avatar class="m-0" size="40px" :src="userImg" />
        <div v-if="isWebPlatform"
                class="h-3 w-3 border-white border border-solid rounded-full absolute pin-r pin-b"
                :class="
                'bg-' + `${this.$store.state.chatUserStatus === this.chatUserStatus.online ? 'success' :
                this.$store.state.chatUserStatus === this.chatUserStatus.idle? 'warning' : 'danger'}`
              "
        ></div>
      </div>
      <section class="user-name">
        <div v-if="!editUserName" class="user-name__display">
          <div>
            <div v-if="takedBy" class="inline-block">
              <h6 class="text-white mb-0">
                <span @click="isAuthAux('konecta.pending_chats.take_chat', true) ? toggleUserNameEdit : null">{{ contactName }}</span> /
                <a @click="showUsing" href="#" class="text-white">
                  🙎 {{ takedBy }}
                </a>
              </h6>
            </div>
            <div v-else class="inline-block">
              <h6  class="text-white mb-0" @click="isAuthAux('konecta.pending_chats.take_chat', true) ? toggleUserNameEdit : null">
                {{ contactName }}
                <small v-if="isWebPlatform"><br/>{{ this.lang.chats.chatUserStatus[this.$store.state.chatUserStatus][languageSelected] }}</small>
              </h6>
            </div>
            <div class="inline-block align-v-top">
              <feather-icon
                      v-tooltip="
              lang.attentionRequests.navbar.editChatName[languageSelected]
            "
                      icon="EditIcon"
                      class="ml-4 cursor-pointer text-white top--3"
                      @click="toggleUserNameEdit"
                      v-if="isAuthAux('konecta.pending_chats.take_chat', true)"
              />
            </div>
          </div>
        </div>
        <div v-else class="user-name__edit">
          <vs-input
            :placeholder="contactName"
            v-model="newUserName"
            v-focus
            @keyup.enter="saveUserName"
            @keyup.esc="cancelUserName"
            @blur="onBlur"
          />
          <div @mousedown.prevent="saveUserName">
            <feather-icon
              icon="CheckIcon"
              class="ml-4 cursor-pointer text-white"
            />
          </div>
          <div @mousedown.prevent="cancelUserName">
            <feather-icon icon="XIcon" class="ml-4 cursor-pointer text-white" />
          </div>
        </div>
      </section>
      <vs-spacer></vs-spacer>
      <NavbarButtons
        v-if="!forbidden && userId"
        :takedBy="takedBy"
        :canStopTakeover="canStopTakeover"
        :user-id="userId"
      />
    </vs-navbar>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ChatService from '../../../../services/chat.service'

import staffManagementMixin from '@/mixins/staffManagementMixin'
import { CHAT_USER_STATUS } from '@/constants/constants'

export default {
  props: {
    userId: {
      type: String,
      required: true
    },
    isPinnedProp: {
      type: Boolean,
      required: true
    },
    isSidebarCollapsed: {
      type: Boolean,
      required: true
    }
  },
  components: {
    NavbarButtons: () => import('./chat-navbar/NavbarButtons')
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      isPinnedLocal: this.isPinnedProp,
      editUserName: false,
      newUserName: '',
      chatUserStatus: CHAT_USER_STATUS,
    }
  },
  watch: {
    isPinnedProp(val) {
      this.isPinnedLocal = val
    }
    // userId() {
    //   this.editUserName = false
    //   this.newUserName = ''
    // }
  },
  computed: {
    ...mapState([
      'lang',
      'languageSelected',
      'session',
      'activeChatMessages',
      'activeChatUser',
      'newChatUserName'
    ]),
    forbidden() {
      return (this.activeChatMessages || []).some(
        item => item.specialEvent === 403
      )
    },
    takedBy() {
      if (
        this.$route.query.bot &&
        this.$route.query.chat &&
        this.activeChatUser.status === 2 &&
        this.activeChatUser.using &&
        this.activeChatUser.using._id &&
        this.activeChatUser.using._id !== this.$store.state.session.user.id
      ) {
        return this.activeChatUser.using.name
      }
      return ''
    },
    canStopTakeover() {
      if (!this.takedBy) {
        return false
      }
      if (this.$store.state.session.roles.indexOf('company_superadmin') > -1) {
        // superadmin can always stop takeover
        return true
      }
      if (
        this.$store.state.session.roles.indexOf('company_supervisor') > -1 &&
        this.activeChatUser.using.roles.indexOf('5cd9a8f9f99a38006e7b8b1c') ===
          -1 &&
        this.activeChatUser.using.roles.indexOf('5cd09eb2e9d215006fff774b') ===
          -1
      ) {
        // supervisor can only stop takeover if it is taken by an agent
        return true
      }
      return false
    },
    userImg() {
      return this.activeChatUser.picture
    },
    contactName() {
      return this.newChatUserName &&
        this.newChatUserName._id === this.activeChatUser._id
        ? this.newChatUserName.fullname
        : this.activeChatUser.fullname
    },
    chatStatusColor() {
      if (this.activeChatUser.status == 1) return 'warning'
      if (this.activeChatUser.status == 2) return 'success'
      if (this.activeChatUser.status == 3) return 'danger'
      return 'custom'
    },
    isWebPlatform(){
        return this.activeChatUser.platform === 'web'
    }
  },
  methods: {
    ...mapMutations(['UPDATE_ACTIVE_CHAT', 'NEW_CHAT_USER_NAME']),
    showUsing() {
      this.$vs.dialog({
        color: this.colorAlert,
        title: `${this.contactName}`,
        text: this.lang.attentionRequests.navbar.using[this.languageSelected].replace('XXX', `${this.activeChatUser.using.name}`).replace('YYY', `${this.activeChatUser.using.email}`),
        accept: () => {/* accept */}
      })
    },
    getUserStatus(isActiveUser) {
      return isActiveUser
        ? this.$store.state.AppActiveUser.status
        : this.contacts[this.contactIndex].userStatus
    },
    toggleIsPinned() {
      const chatData = this.$store.getters['chat/chatDataOfUser'](this.userId)
      if (chatData) {
        const payload = { id: this.userId, value: !this.isPinnedLocal }
        this.$store.dispatch('chat/toggleIsPinned', payload)
      } else {
        this.$emit('toggleIsChatPinned', !this.isPinnedLocal)
      }
      this.isPinnedLocal = !this.isPinnedLocal
    },
    toggleUserNameEdit() {
      this.editUserName = !this.editUserName
      if (this.editUserName) {
        this.newUserName = this.contactName
      }
    },
    saveUserName() {
      this.$vs.loading()

      const payload = {
        fullname: this.newUserName
      }
      ChatService.updateChat(this.activeChatUser._id, payload)
        .then(response => {
          this.UPDATE_ACTIVE_CHAT(response.data)
          this.toggleUserNameEdit()

          this.$vs.notify({
            text: this.lang.chats.messages.updateChatName.success.text[
              this.languageSelected
            ],
            color: 'success',
            position: 'top-right'
          })

          this.NEW_CHAT_USER_NAME(this.activeChatUser)
        })
        .catch(() => {
          this.$vs.notify({
            title: this.lang.chats.messages.updateChatName.error.title[
              this.languageSelected
            ],
            text: this.lang.chats.messages.updateChatName.error.text[
              this.languageSelected
            ],
            color: 'danger',
            position: 'top-right'
          })
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    cancelUserName() {
      this.toggleUserNameEdit()
      this.newUserName = ''
    },
    onBlur() {
      this.editUserName = false
      this.newUserName = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.chat__header {
  .con-vs-avatar {
    cursor: default;
  }
}
.bg-custom-gradient {
  background: linear-gradient(
    118deg,
    rgb(231, 124, 92),
    rgba(163, 9, 9, 0.507)
  ) !important;
}
.bg-custom {
  background: linear-gradient(
    118deg,
    rgb(231, 124, 92),
    rgba(255, 255, 255, 0.507)
  ) !important;
}
.user-name {
  &__display,
  &__edit {
    display: flex;
    flex-direction: row;
    align-items: center;

    .feather-icon {
      width: 14px;
    }
  }
}
.inline-block{
  display: inline-block;
}
.top--3{
  top: -3px;
}
.align-v-top{
  vertical-align: top;
}
</style>
